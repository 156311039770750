<template>
<div>
    <p>&nbsp;</p>
    <p class="text-xl">ผู้ตอบแบบวัดการรับรู้ของผู้มีส่วนได้ส่วนเสียภายนอก</p>
    <p>&nbsp;</p>
    <table    width="100%">
        <tbody>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อายุ</td>
                <td style="width: 218.922px; height: 51px;">ชาย</td>
                <td style="width: 218.922px; height: 51px;">หญิง</td>
                <td style="width: 226.234px; height: 51px;">อื่นๆ</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อายุต่ำกว่า 20 ปี&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;14</td>
                <td style="width: 218.922px; height: 51px;">27</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">21 - 30 ปี</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;77</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;70</td>
                <td style="width: 226.234px; height: 51px;">&nbsp;0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">31 - 40 ปี</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;25</td>
                <td style="width: 218.922px; height: 51px;">24&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">2&nbsp;</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">41 - 50 ปี&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;34</td>
                <td style="width: 218.922px; height: 51px;">21&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">6&nbsp;</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">51 - 60 ปี&nbsp;&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">11&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">5&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">1&nbsp;</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">&nbsp;มากกว่า 60 ปี</td>
                <td style="width: 218.922px; height: 51px;">1&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">3&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">ระดับการศึกษา</td>
                <td style="width: 218.922px; height: 51px;">ชาย</td>
                <td style="width: 218.922px; height: 51px;">หญิง</td>
                <td style="width: 226.234px; height: 51px;">อื่นๆ</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">
                    <p>&nbsp;</p>
                    <p>ประถมศึกษาหรือต่ำกว่า</p>
                </td>
                <td style="width: 218.922px; height: 51px;">0</td>
                <td style="width: 218.922px; height: 51px;">1</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">มัธยมศึกษาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;55</td>
                <td style="width: 218.922px; height: 51px;">73</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อนุปริญญาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">12</td>
                <td style="width: 218.922px; height: 51px;">7</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">ปริญญาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">77</td>
                <td style="width: 218.922px; height: 51px;">61&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">0&nbsp;</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">สงกว่าปริญญาตรี</td>
                <td style="width: 218.922px; height: 51px;">16&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">6&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">0&nbsp;</td>
            </tr>
            <tr style="height: 50.5px;">
                <td style="width: 218.922px; height: 50.5px;">&nbsp;อื่นๆ</td>
                <td style="width: 218.922px; height: 50.5px;">2</td>
                <td style="width: 218.922px; height: 50.5px;">2&nbsp;</td>
                <td style="width: 226.234px; height: 50.5px;">9</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">ติดต่อหน่วยงาน</td>
                <td style="width: 218.922px; height: 51px;">ชาย</td>
                <td style="width: 218.922px; height: 51px;">หญิง</td>
                <td style="width: 226.234px; height: 51px;">อื่นๆ</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">
                    <p>&nbsp;บุคคลทั่วไป</p>
                </td>
                <td style="width: 218.922px; height: 51px;">117</td>
                <td style="width: 218.922px; height: 51px;">109</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">หน่วยงานของรัฐ</td>
                <td style="width: 218.922px; height: 51px;">11</td>
                <td style="width: 218.922px; height: 51px;">11</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">องค์กรธุรกิจ</td>
                <td style="width: 218.922px; height: 51px;">25</td>
                <td style="width: 218.922px; height: 51px;">21&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">4&nbsp;</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อื่นๆ</td>
                <td style="width: 218.922px; height: 51px;">9</td>
                <td style="width: 218.922px; height: 51px;">9&nbsp;</td>
                <td style="width: 226.234px; height: 51px;">5&nbsp;</td>
            </tr>
        </tbody>
    </table>

    <p>&nbsp;</p>
    <p class="text-xl">ผู้ตอบแบบวัดการรับรู้ของผู้มีส่วนได้ส่วนเสียภายใน</p>
    <p>&nbsp;</p>
    <table style="height: 261px; width : 100%;" width="100%">
        <tbody>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อายุ</td>
                <td style="width: 218.922px; height: 51px;">ชาย</td>
                <td style="width: 218.922px; height: 51px;">หญิง</td>
                <td style="width: 226.234px; height: 51px;">อื่นๆ</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อายุต่ำกว่า 20 ปี&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">&nbsp;0</td>
                <td style="width: 218.922px; height: 51px;">0</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">21 - 30 ปี</td>
                <td style="width: 218.922px; height: 51px;">16</td>
                <td style="width: 218.922px; height: 51px;">20</td>
                <td style="width: 226.234px; height: 51px;">1</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">31 - 40 ปี</td>
                <td style="width: 218.922px; height: 51px;">39</td>
                <td style="width: 218.922px; height: 51px;">70</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">41 - 50 ปี&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">14</td>
                <td style="width: 218.922px; height: 51px;">31</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">51 - 60 ปี&nbsp;&nbsp;</td>
                <td style="width: 218.922px; height: 51px;">7</td>
                <td style="width: 218.922px; height: 51px;">6</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">&nbsp;มากกว่า 60 ปี</td>
                <td style="width: 218.922px; height: 51px;">2</td>
                <td style="width: 218.922px; height: 51px;">1</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">ระดับการศึกษา</td>
                <td style="width: 218.922px; height: 51px;">ชาย</td>
                <td style="width: 218.922px; height: 51px;">หญิง</td>
                <td style="width: 226.234px; height: 51px;">อื่นๆ</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">
                    <p>&nbsp;</p>
                    <p>ประถมศึกษาหรือต่ำกว่า</p>
                </td>
                <td style="width: 218.922px; height: 51px;">2</td>
                <td style="width: 218.922px; height: 51px;">2</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">มัธยมศึกษาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">3</td>
                <td style="width: 218.922px; height: 51px;">1</td>
                <td style="width: 226.234px; height: 51px;">1</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">อนุปริญญาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">0</td>
                <td style="width: 218.922px; height: 51px;">2</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">ปริญญาหรือเทียบเท่า</td>
                <td style="width: 218.922px; height: 51px;">42</td>
                <td style="width: 218.922px; height: 51px;">63</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 51px;">
                <td style="width: 218.922px; height: 51px;">สงกว่าปริญญาตรี</td>
                <td style="width: 218.922px; height: 51px;">31</td>
                <td style="width: 218.922px; height: 51px;">58</td>
                <td style="width: 226.234px; height: 51px;">0</td>
            </tr>
            <tr style="height: 50.5px;">
                <td style="width: 218.922px; height: 50.5px;">&nbsp;อื่นๆ</td>
                <td style="width: 218.922px; height: 50.5px;">0</td>
                <td style="width: 218.922px; height: 50.5px;">2</td>
                <td style="width: 226.234px; height: 50.5px;">0</td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
export default {

}
</script>

<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>