






















































































import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Web } from "@/store/web";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import _ from 'lodash'
import XLSX from 'xlsx' // import xlsx
import { Result } from '@/store/result'
import { CoreResult } from '@/store/core_result'
import { Report } from '@/store/report'

@Component({
    components: {
        Navbar,
        Loading,

    },
    computed: {}
})

export default class TestDevClass extends Vue {
    @Prop({ default: 7 })
    agencyData: any;

    @Prop({ default: '2563' })
    yearData: any;
    
    yearFull:any;

    agency: any = null
    assignments: any = null

    year: any = null;
    issues: any = [];
    response: boolean = false;
    score: any = ''
    score30: number = 0
    chooseAssignId: number = 1;
    allUser: number = 0;
    allAgency: number = 0;
    result: any = ''
    
    feedbacks:any = []


    async getIssue() {
        this.yearFull =  await Report.getYearIIT(this.yearData)
        this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.agencyData}/`)
        let raw = await Core.getHttp(`/api/report/v1/reportrawiit/?agency=${this.agencyData}&year=${this.yearData}`)
        if (raw.length > 0) {
            let data = raw[0]
            this.allUser = data.user_do
            this.allAgency = data.user_set
            this.assignments = JSON.parse(data.rawType)
            this.year = data.year
            this.chooseAssignId = 1
            this.issues = JSON.parse(data.rawDone)
            this.score = data.score
            this.score30 = data.score30
            this.result = data.result
            this.response = true;
        }
        console.log(raw)

         this.feedbacks  = await Core.getHttp(`/api/iit/v2/answersuggestion/?agency=${this.agencyData}&year=${this.yearFull.id}`)

        console.log(this.issues);
    }

    async created() {
        await Web.switchLoad(true)

        await this.getIssue();

        await Web.switchLoad(false)

    }

}
